import React, { useContext, useState } from 'react';
import { FaDownload, FaPlus, FaTrash } from 'react-icons/fa';
import { RiLoader2Line } from 'react-icons/ri';
import { GeneralContext, ModalContext } from '~/contexts';
import dates from '~/utils/dates';
import api from '~/services/api';
import './styles.css';
import FormBill from '~/pages/Bills/Form';

// Images
import icon from './images/icon.svg';
import Button from '~/components/Parts/Forms/Button';

interface Props {
    companyName  : string | null;
    bill         : number;
    userName     : string;
    pdf          : string;
    date         : string;
    createBill?  : any;
    setReady?    : Function;
    setBillError?: Function;
    delBill?     : Function;
    setMessages  : React.Dispatch<React.SetStateAction<any>>;
}

const BillBox: React.FC<any> = (props: Props) => {
    const { user }                  = useContext(GeneralContext);
    const Modal                     = useContext(ModalContext);
    const [downReady, setDownReady] = useState<Boolean>(true);
    const dateFormat                = dates._getDateByDate(props.date);


    function downloadPDF(path: string) {
        setDownReady(false);

        api.get(`bill/${path}`, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then(resp => {
            let url = window.URL.createObjectURL(new Blob([resp.data]));
            let link = document.createElement('a');
            let fileName = `${props.companyName ? props.companyName : 'Sem Nome'} - ${dateFormat.monthName} de ${dateFormat.year}.pdf`;

            setTimeout(() => {
                link.href = url;
                link.setAttribute("id", "download");
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                setDownReady(true);
            }, 1000);
        })
    }

    function openModalBill() {
        Modal.setModalOpen(false);
        Modal.setModalClass('enz mbills');
        Modal.setModalTitle(props.companyName)
        Modal.setModalBody(<FormBill setBillError={props.setBillError} setReady={props.setReady} setMessages={props.setMessages} bill={props.bill} />)
        Modal.setModalOpen(true)
    }

    

    return <>
        <div className="client">{props.companyName}</div>
        <div className="provider">{props.userName}</div>
        <div className="dateInfo">
            {dates._getDateByDate(props.date).day} de {dates._getDateByDate(props.date).monthName} de {dates._getDateByDate(props.date).year}
        </div>
        <div className="buttons">
            <Button
                type="button"
                className={`down`}
                icon={<FaPlus size={10} />}
                text={"Cadastrar"}
                func={() => openModalBill()}
            />

            <Button
                type="button"
                className={`down${!downReady ? ' load' : ''}`}
                icon={!downReady ? <RiLoader2Line size={18} /> : <FaDownload />}
                text={null}
                func={() => downloadPDF(props.pdf)}
                disabled={!downReady ? true : false}
            />

            <Button
                type="button"
                className='delete'
                icon={<FaTrash />}
                text={null}
                func={() => props.delBill && props.delBill(props.bill)}
                disabled={!props.delBill ? true : false}
            />
        </div>
    </>
}

export default BillBox;