import React, { useContext, useEffect, useState, ChangeEvent } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import api from '~/services/api';
import Dates from '~/utils/dates';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Loading, { LoadingSending } from '~/components/Default/Loading';
import MailerBox from '~/components/Boxs/AdminMailers/Box';
import StatusBox from '~/components/Boxs/AdminMailers/Status';
import { TextField } from '@mui/material';

const FormSender: React.FC<any> = ({setReadyPage, type}) => {
    const [ready, setReady]           = useState<boolean>(false);
    const [sending, setSending]       = useState<boolean>(false);
    const [checkedAll, setCheckedAll] = useState<boolean>(false);
    const [error, setError]           = useState<string>('');
    const [setup, setSetup]           = useState<any>({year: '', month: '', name: ''});
    const [dateOpen, setDatesOpen]    = useState<any>({limitPayDate: false, suggestedPayDate: false});
    const [payDates, setPayDates]     = useState<any>({limitPayDate: '', suggestedPayDate: ''});
    const [months, setMonths]         = useState<any[]>([]);
    const [mails, setMails]           = useState<any[]>([]);
    const [selMails, setSelMails]     = useState<any[]>([]);
    const [infos, setInfos]           = useState<any[]>([]);

    // Context
    const {user} = useContext(GeneralContext);

    function onChange(event: ChangeEvent<HTMLSelectElement>) {
        const {value}  = event.target;
        let data: any  = JSON.parse(value);
        let merge: any = {...setup, ...data};

        // Set Infos
        setSetup(merge);
        setMailers(merge.year, merge.month);
        setReady(false);
    }

    function onChangeDate (date: any, type: string) {
        let newDate = Dates.getDateFormatted(date, 'dd/mm/yyyy')
        let data    = {...payDates, [type]: newDate}

        setPayDates(data)
    }

    function setDateOpen (type: string, status: boolean) {
        setDatesOpen({...dateOpen, [type]: status})
    }

    async function sendMails () {
        setSending(true);

        if (selMails.length===0) {
            setError('Você precisa selecionar ao menos uma unidade')
            setSending(false)
            return;
        }

        let data = {
            type,
            mails           : selMails,
            yearMonth       : setup.year + '-' + setup.month,
            limitPayDate    : payDates.limitPayDate.split('/').reverse().join('-'),
            suggestedPayDate: payDates.suggestedPayDate.split('/').reverse().join('-')
        }

        let mailRet = await api.post(
            'mailers/send',
            data,
            {headers: { Authorization: user.token }}
        ).then(resp => resp.data);

        setInfos(mailRet);
        setSending(false);
        setReadyPage(false);
    }

    async function setMailers (year?: string | number, month?: string | number) {
        setMails([]);

        // Set Year and Month
        year  = year ? year : setup.year;
        month = month ? month : setup.month;

        let mailers = await api.get(`mailers/list/${type}?year=${year}&month=${month}`, {headers: { Authorization: user.token }}).then(resp => resp.data);
        setTimeout(() => {
            setMails(mailers)
            setReady(true)
        }, 1000);
    }

    function selectAll (event: ChangeEvent<HTMLInputElement>) {
        let checked = event.currentTarget.checked;

        if (checked) {
            setSelMails(mails)
            setCheckedAll(true)
        } else {
            setSelMails([])
            setCheckedAll(false)
        }
    }

    function renderMailers () {
        let form: any = <></>;
        let list: any = <></>;

        if (!sending && infos.length===0) {
            form = <div className="form-group">
                <div className="input-group">
                    <label htmlFor="date">Data</label>
                    <select
                        name="date" id="date"
                        onChange={onChange}
                        defaultValue={setup ? setup : ''}
                    >
                        {
                            months.length>0 && months.map((el: any) =>(
                                <option key={`months_${el.year}_${el.month}`} value={JSON.stringify(el)}>
                                    {el.name + ' de ' + el.year}
                                </option>
                            ))
                        }
                    </select>
                </div>

                <div className="input-group date">
                    <label htmlFor="date">Data de Pagamento</label>
                    <input
                        id="limit_pay_date"
                        name="limit_pay_date"
                        type="text"
                        placeholder='01/10/2020'
                        data-type="date"
                        value={payDates.limitPayDate}
                        onClick={() => {setDateOpen('limitPayDate', !dateOpen.limitPayDate)}}
                        readOnly
                    />
                    <DatePicker
                        open={dateOpen.limitPayDate}
                        onOpen={() => setDateOpen('limitPayDate', true)}
                        onClose={() => setDateOpen('limitPayDate', false)}
                        value={new Date(payDates.limitPayDate)}
                        disablePast
                        onChange={(date) => onChangeDate(date, 'limitPayDate')}
                        views={['year', 'month', 'day']}
                        renderInput={(params) => (
                            <TextField {...params} size="small" variant="outlined" fullWidth />
                        )}
                    />
                </div>

                <div className="input-group date">
                    <label htmlFor="date">Sugestão de Pagamento</label>
                    <input
                        id="suggested_pay_date"
                        name="suggested_pay_date"
                        type="text"
                        placeholder='10/10/2020'
                        data-type="date"
                        value={payDates.suggestedPayDate}
                        onClick={() => {setDateOpen('suggestedPayDate', !dateOpen.suggestedPayDate)}}
                        readOnly
                    />
                    <DatePicker
                        open={dateOpen.suggestedPayDate}
                        onOpen={() => setDateOpen('suggestedPayDate', true)}
                        onClose={() => setDateOpen('suggestedPayDate', false)}
                        value={new Date(payDates.suggestedPayDate)}
                        disablePast
                        onChange={(date) => onChangeDate(date, 'suggestedPayDate')}
                        views={['year', 'month', 'day']}
                        renderInput={(params) => (
                            <TextField {...params} size="small" variant="outlined" fullWidth />
                        )}
                    />
                </div>

                <div className="buttons">
                    <button onClick={sendMails} disabled={
                        mails.length>0 &&
                        selMails.length>0 &&
                        payDates.limitPayDate!=='' &&
                        payDates.suggestedPayDate!=='' ? false : true
                    }>Enviar</button>
                </div>
            </div>

            if (ready) {
                list = mails.length>0 ? (
                    <div className="mailersBox">
                        <div className="header">
                            <div className="sel"><input type="checkbox" value="all" onChange={selectAll} checked={checkedAll} title="Selecionar Todos" /></div>
                            <div className="unit">Unidade</div>
                        </div>

                        <div className="mailList">
                            {
                                mails.map((el: any) => <MailerBox
                                        key={`box_mailer_${el.id}`}
                                        {...el}
                                        mails={selMails}
                                        setMail={setSelMails}
                                    />
                                )
                            }
                        </div>
                    </div>
                ) : (
                    <div className="alert">Nenhuma unidade localizada para o mês selecionado</div>
                )
            } else {
                list = <Loading />
            }
        } else {
            form = <div className="sending">
                Enviando <b>{type==='salesOff' ? 'Garantia Financeira' : 'Energia de Reserva'}</b> referente a <b>{setup.name} de {setup.year}</b>
            </div>

            list = infos.length>0 ? (
                <div className={`infosBox${infos.length===0 ? ' loading' : ''}`}>
                    <div className="header">
                        <div className="status" title='Status do Envio'></div>
                        <div className="unit">Unidade</div>
                        <div className="mail">Email</div>
                    </div>

                    <div className="statusList">
                        {
                            infos.map((el: any, index: number) => (
                                <StatusBox key={`info_${index}`} {...el} />
                            ))
                        }
                    </div>
                </div>
            ) : <LoadingSending />
        }

        return <>
            { form }
            <hr />
            { error!=='' && <div className="error">{error}</div> }
            { list }
        </>;
    }

    useEffect(() => {
        if (!ready && !setup.year && !setup.month) {
            let months    = Dates.getMonthsYear(false)
            setMonths(months)
            setSetup({year: months[0].year, month: months[0].month, name: months[0].name})
            setMailers(months[0].year, months[0].month);
        }
    }, [ready])

    useEffect(() => {
        if (ready) setCheckedAll(mails.length===selMails.length ? true : false)
        setError('')
    }, [selMails])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
            <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="sendMailer">
                { renderMailers() }
            </form>
        </LocalizationProvider>
    );
}

export default FormSender;