import React, { useContext, useEffect, useState } from 'react';
import { FaDownload, FaSearch, FaTimes } from 'react-icons/fa';
import { GeneralContext, PaginationContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Admin from '~/components/Admin';
import LoadingSimple from '~/components/Default/Loading/Simple';
import BillHistoryBox from '~/pages/Administrator/ReadingHistory/history';
import ReactTooltip from 'react-tooltip';
import Button from '~/components/Parts/Forms/Button';

import dates from "~/utils/dates";
import { RiLoader2Line } from 'react-icons/ri';

const ReadingHistory: React.FC<any> = (props: any) => {
  const { user, breadcrumbs, setBreadcrumbs }       = useContext(GeneralContext);
  const [ready, setReady]                           = useState(false);
  const [filterCompleted, setFilterCompleted]       = useState(false);
  const [errors, setErrors]                         = useState<any>(null);
  const [errorsList, setErrorsList]                 = useState<any>([]);
  const [search, setSearch]                         = useState('');
  const [billReadingHistory, setBillReadingHistory] = useState('1');
  const [messages, setMessages]                     = useState<any>(<></>);
  const Pagination                                  = useContext(PaginationContext);
  
  function onChangeSearch(event: any) {
    let data = event.target.value;
    let errorsFilter = errorsList.filter((el: any) => {
      const unitName = el.units_name || '';
      const providerName = el.provider || '';

      return unitName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0 ||
        providerName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0;
    });
    if (data === "") {
      const filteredComErrors = errorsList.filter((el: any) => el.status === "Waiting");
      setErrors(filteredComErrors);
    } else {
      setErrors(errorsFilter);
    }

    setErrors(errorsFilter)
    setSearch(data)

    Pagination.setCurrentPage(1);
    Pagination.setData(errorsFilter);
  }

  function onChangeSelect(event: any) {
    const selectedValue = event.target.value;
    setBillReadingHistory(selectedValue)

    if (selectedValue === '0') {
      const filteredErrors = errorsList.filter((el: any) => el.status === "Read");
      setErrors(filteredErrors);
      setReady(true)
      setFilterCompleted(true)

      Pagination.setCurrentPage(1);
      Pagination.setData(filteredErrors);
    } else if (selectedValue === '1') {
      const filteredComErrors = errorsList.filter((el: any) => el.status === "Waiting");
      setErrors(filteredComErrors);
      setReady(true)

      Pagination.setCurrentPage(1);
      Pagination.setData(filteredComErrors);
    } else {
      setErrors(errorsList);
      setReady(true)

      Pagination.setCurrentPage(1);
      Pagination.setData(errorsList);
    }
  }

  useEffect(() => {
    Pagination.init();
    Pagination.setItemsPerPage(13);

    if (errors === null) {
      setReady(false)

      api.get(`/bills/all/bill-history`, { headers: { Authorization: user.token } })
        .then(resp => {
          const valueBill = resp.data.filter((el: any) => el.bill_number === null || (el.bill_number && !el.bill_number.includes("Manual_")));
          const filtered = valueBill.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          const filteredWaiting = filtered.filter((el: any) => el.status === "Waiting");

          setErrorsList(filtered);
          setErrors(filteredWaiting);
          Pagination.setData(filteredWaiting)
          setReady(true);

        })
    }
  }, [user, ready, errors]);


  // Breadcrumbs
  useEffect(() => {
    ready && !breadcrumbs && setBreadcrumbs({
      curr: 'Historico de contas dos Clientes',
      links: [
        { name: 'Lista de Clientes', url: '/admin/s/clientes' }
      ]
    });
  }, [ready])

  // Unmount
  useEffect(() => () => setBreadcrumbs(null), [])

  return (
    <Admin pageTitle="Administrador - Clientes - Historico de contas" title="Gerenciamento do historico de contas" className="cliBillError">
      {ready ? (
        <>
          <div className="header">
            <div className="messageInfo">
              {messages}
            </div>
            <div className="searchBox">
              <select id="faturas" name="faturas" defaultValue="1" onChange={onChangeSelect}>
                
                <option value="1">Faturas em leitura</option>
                <option value="0">Faturas concluidas</option>
              </select>

              <input
                type="text"
                name="search"
                id="search"
                placeholder="Filtrar Contas..."
                value={search}
                onChange={onChangeSearch}
                disabled={ready && errors ? false : true}
              />

              {search !== '' && (
                <button onClick={() => setSearch('')}>
                  <FaTimes />
                </button>
              )}
            </div>
          </div>

          <div className="billsErrorsBoxReadingHistory">
            <div className='headerInfoReadingHistory'>
              <div className='client'>Cliente</div>
              <div className='provider'>Distribuidora</div>
             {/*  {filterCompleted === true ? 
              <div className='dateRef'>Data Referente</div> : <></>} */}
              <div className='status'>Status</div>
              <div className='dateInfo'>Data de envio</div>
              <div className='buttons'>Download</div>
            </div>

            <section>
              {errors && errors.length > 0 ? (
                Pagination.currentData.map((el: any, index: number) => (
                  <div className={`billBoxReadingHistory ${index % 2 === 0 ? '' : 'even-id'}`} key={el.id}>
                    <BillHistoryBox
                      key={`box_${el.id}`}
                      bill_id={el.id}
                      pdf={el.pdf_path}
                      path={el.path}
                      companyName={el.units_name}
                      setBillError={setErrors}
                      date={el.reference_date}
                      Bill={el}
                      setMessages={setMessages}
                    />
                  </div>
                ))
              ) : null}
            </section>
          </div>

          {Pagination.renderPagination()}
          <ReactTooltip id="tooltip_units_page" place="top" effect="solid" type="info" />
        </>
      ) : (
        <LoadingSimple />
      )}

    </Admin>
  );

}

export default ReadingHistory;