import React, { useContext, useEffect, useState } from 'react';
import { FaDownload, FaPlus, FaTimes, FaTrash } from 'react-icons/fa';
import { GeneralContext, ModalContext, PaginationContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';
import FormBill from '~/pages/Bills/Form';

import dates from '~/utils/dates';
// Images
import icon from './images/icon.svg';
import Button from '~/components/Parts/Forms/Button';
// Components
//import Template from '~/pages/admin/Template';
import Admin from '~/components/Admin';

import LoadingSimple from '~/components/Default/Loading/Simple';

import BillErrorBox from '~/pages/Administrator/ErrorsUpload/errors';
import { RiLoader2Line } from 'react-icons/ri';
import ReactTooltip from 'react-tooltip';

const ListErrorsForAdminHelp: React.FC<any> = (props: any) => {
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const [ready, setReady]                     = useState(false);
    const [errors, setErrors]                   = useState<any>(null);
    const [errorsList, setErrorsList]           = useState<any>([]);
    const [search, setSearch]                   = useState('');
    const [billHelpAdmin, setBillHelpAdmin]     = useState('1');
    const [messages, setMessages]               = useState<any>(<></>);
    const Pagination                            = useContext(PaginationContext);


    const Modal = useContext(ModalContext);

    const [downReady, setDownReady] = useState<Boolean>(true);


    function onChangeSearch(event: any) {
        let data = event.target.value;
        let errorsFilter = errorsList.filter((el: any) =>
            el.unit.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0 ||
                (el.status === 'not_rated' && 'Não Avaliado'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0) ||
                (el.status === 'validated' && 'Validado'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0) ||
                (el.status === 'errors' && 'Com erros'.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0)
                ? true : false
        )

        if (data === "") {
            setErrors(errorsFilter.filter((el: any) => el.help_admin === parseInt(billHelpAdmin)));
        } else {
            setErrors(errorsFilter);
        }

        setSearch(data)
        Pagination.setCurrentPage(1);
        Pagination.setData(data);
    }

    function onChangeSelect(event: any) {
        const selectedValue = event.target.value;
        setBillHelpAdmin(selectedValue)

        if (selectedValue === '0') {
            const filteredErrors = errorsList.filter((el: any) => el.help_admin === parseInt('0'));
            setErrors(filteredErrors);

            Pagination.setCurrentPage(1);
            Pagination.setData(filteredErrors);
        } else {
            const filteredComErrors = errorsList.filter((el: any) => el.help_admin === parseInt('1'));
            setErrors(filteredComErrors);
            Pagination.setCurrentPage(1);
            Pagination.setData(filteredComErrors);
        }
    }

    async function delBill(id: number) {
        await api.delete(`/bills/errors/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setReady(true)
            setErrors(null)
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        Pagination.init();
        Pagination.setItemsPerPage(13);


        if (errors === null) {
            setReady(false)

            api.get(`/bills/all/errors`, { headers: { Authorization: user.token } })
                .then(resp => setTimeout(() => {
                    setErrorsList(resp.data)
                    const filteredErrors = resp.data.filter((el: any) => el.help_admin === parseInt('1'));
                    setErrors(filteredErrors)
                    Pagination.setData(filteredErrors)
                    console.log("🚀 ~ .then ~ filteredErrors:", filteredErrors)
                    setReady(true)
                }, 2000))
                    
        }
    }, [user, ready, errors]);

    // Breadcrumbs
    useEffect(() => {
        ready && !breadcrumbs && setBreadcrumbs({
            curr: 'Contas com erros do Cliente',
            links: [
                { name: 'Lista de Clientes', url: '/admin/s/clientes' }
            ]
        });
    }, [ready])

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Admin pageTitle="Administrador - Clientes - Contas com erros" title="Gerenciamento de Contas com erros do Cliente" className="cliBillError">
        {
            ready ? (<>
                <div className="header">
                    {/* <h1>Contas com erros do Cliente {client.name.split('|').join(' ')}</h1> */}
                    <div className="messageInfo">
                        {messages}
                    </div>
                    <div className="searchBox">

                        <select id="faturas" name="faturas" defaultValue="1" onChange={onChangeSelect}>
                            <option value="1" >Faturas com solicitação</option>
                            <option value="0">Faturas sem solicitação</option>
                        </select>

                        <input type="text" name="search" id="search" placeholder='Filtrar Contas...' value={search} onChange={onChangeSearch} disabled={ready && errors ? false : true} />

                        {search !== '' && <button onClick={() => {
                            setSearch('')
                            setErrors(errorsList.filter((el: any) => el.help_admin == parseInt('1')))
                        }}>
                            <FaTimes />
                        </button>}

                    </div>
                </div>

                <div className="billsErrorsBoxUp">
                    <div className='headerInfoUp'>
                        <div className='client'>Cliente</div>
                        <div className='provider'>Usuario</div>
                        <div className='dateInfo'>Data de envio</div>
                        <div className='buttons'></div>
                    </div>

                    <section>
                        {errors && errors.length > 0 ? (
                            Pagination.currentData.map((el: any, index: number) => (
                                <div className={`billBoxUp ${index % 2 === 0 ? '' : 'even-id'}`} key={el.id}>
                                    <BillErrorBox
                                        key={`box_${el.id}`}
                                        bill={el.id}
                                        pdf={el.pdf_path}
                                        companyName={el.unit.name}
                                        userName={el.json.user.name}
                                        setBillError={setErrors}
                                        date={el.date}
                                        delBill={delBill}
                                        createBill={el}
                                        setMessages={setMessages}
                                    />
                                </div>
                            ))
                        ) : null}
                    </section>
                </div>

                {Pagination.renderPagination()}
                <ReactTooltip id="tooltip_units_page" place="top" effect="solid" type="info" />
            </>
            ) : (
                <LoadingSimple />
            )}

    </Admin>

}

export default ListErrorsForAdminHelp;